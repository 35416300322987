import "./App.css";
import About from "./Components/About";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Contact from "./Components/Contact";
import {Resources} from "./Components/resources";
import {FreeSources} from "./Components/freeSources";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

//Google Analytics
const TRACKING_ID = "UA-138231200-1"; 
ReactGA.initialize(TRACKING_ID);

// Google Tag Manager
const tagManagerArgs = {
    gtmId: 'GTM-PG3C4R3'
};

TagManager.initialize(tagManagerArgs);


function App() {
  return (
    <div className="App">
      <Navbar />
      <About />
      <Resources />
      <FreeSources />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
