import contactme from "../Assets/contactme.png"
import wilbookslogo from "../Assets/wilbookslogo.png";
import colorincolorado from "../Assets/colorincolarado.png";
import youtubelogo from "../Assets/youtubelogo.png";
import dollypartonbanner from "../Assets/DPIL-Logo-195.png"
import readingisfundamental from "../Assets/readingisfundemental.png"

export const RESOURCES = [
  {
    id: 1,
    text: "Request a book donation for your school or literacy project",
    url: "#contact",
    image: contactme,
  },
  {
    id: 2,
    text: "Every child can get a free autographed book!",
    url: "https://www.wilbooks.com/free-autographed-book-request",
    image: wilbookslogo,
  },
  {
    id: 3,
    text: "A great bilingual website",
    url: "http://www.colorincolorado.org/",
    image: colorincolorado,
  },
  {
    id: 4,
    text: "Dolly Parton's Imagination Library",
    url: "https://imaginationlibrary.com/",
    image: dollypartonbanner,
  },
  {
    id: 5,
    text: "You can read more than 1,000 free online books in English and Spanish.",
    url: "https://www.wilbooks.com/free-resources-free-online-books",
    image: wilbookslogo,
  },
  {
    id: 6,
    text: "A great video for basic ESL and ELL students.",
    url: "https://www.youtube.com/watch?v=k94tp_lC1hc",
    image: youtubelogo,
  },
  {
    id: 7,
    text: "Reading Is Fundamental",
    url: "https://www.rif.org/",
    image: readingisfundamental,
  },
];