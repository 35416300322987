import React from "react";



export const FreeSource = (props) => {
  const { location, sources } = props.data;

  
  return (
    <div>
    <div className="location-container" >
      {location}
    </div>
    { Object.keys(sources).map((key, index) => (
            <div className="resource-container">
            <a href={sources[key].url} className="resource-link">
              <div className="logo-container">
                <img className="logo-image" loading="lazy" src={require('../Assets/'.concat(sources[key].logo))}alt={sources[key].text}/> 
                
              </div>
      
              <div className="text-container"> 
                  <p className="customlinks">{sources[key].text}</p>
              </div>
            </a>
          </div>
    ))}
    </div>
  );


};


