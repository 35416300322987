import React from "react";
import { Box } from "@mui/material";

import "./About.css"
import hero from "../Assets/brucehiking.jpg"
import contactme from "../Assets/contactme.png"

const About = () => {
  return (
    <Box  backgroundColor="#1C92D2" width="100%">
    
      <div className="hero">
        <div className="literacysuperhero">
          <div >
              Literacy Superhero
              <p className="jackalopeman">
                Bruce "the Jackalope Man" Larkin
              </p>
              <p className="authorpoet">
                Children's Book Author &amp; Poet
              </p>
          </div>
        </div>
        <div className="contactme-container">
          <div >
            <a href="#contact">
              <img className="contactme" src={contactme} alt="contact me"/>
            </a>

          </div>
        </div>
        

        <img className="heroimage" loading="lazy"  src={hero} alt="hero"/>

      </div>
      <div className="imbruce-container">
       
        <p className="imbruce">
            Hello, I'm Bruce "the Jackalope Man" Larkin, most of you know me as a children's book author and poet. 

            I am also a tireless literacy advocate and philanthropist. 

            The largest number of books I have ever donated in one year is 400,000. 

            In the coming year, I will donate more than 500,000 books to schools and literacy projects all across the country! 

            I created this simple website to make it easier for people who care about literacy to find resources. 

            Please share this page with every parent, teacher, and literacy activist that you know.
          </p>

        </div>
      


      </Box>
  );
};

export default About;
