import React from "react";
// import logo from "../Assets/logo.svg";
// import { BsTwitter } from "react-icons/bs";
// import { SiLinkedin } from "react-icons/si";
// import { BsYoutube } from "react-icons/bs";
// import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <div></div>
    // <div className="footer-wrapper">
    //   <div className="footer-section-one">
    //     <div className="footer-logo-container">
    //       <img src={logo} alt="" />
    //     </div>
    //   </div>
    //     <div align="left" className="footer-section-columns">
    //       <span>Terms & Conditions</span>
    //       <span>Privacy Policy</span>
    //     </div>
    //   </div>
  );
};

export default Footer;
