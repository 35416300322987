import React from "react";
// import { Link } from "react-router-dom";


export const Resource = (props) => {
  const { id, text, url, image } = props.data;

  return (
    <div className="resource-container">
      <a href={`${url}`} className="resource-link">
        <div className="logo-container">
          <img className="logo-image" loading="lazy" src={image} alt={id}/> 
        </div>

        <div className="text-container"> 
            <p className="customlinks">{text}</p>
        </div>
      </a>
    </div>
  );
};
