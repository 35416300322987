import { Box } from "@mui/material";
import React from "react";

import { RESOURCES } from "../Data/resources";
import { Resource } from "./resource";


import "./resources.css";

const Resources = () => {
  return (
    <Box className="block-container"
      // gridColumn="span 8"
      // gridRow="span 3"
       backgroundColor="#58D68D"
      // marginBottom="10px"
    >

      <Box className="header-container" >
        <div className="header-container">
            <p className="header-element">
            Free Resources That Are Available Nationally
            </p>
        </div>
      </Box>

      <div className="resources">
        {RESOURCES.map((resource) => (
          <Resource data={resource} />
        ))}
      </div>

  

    </Box>

  
  );

};

export { Resources};
