import React from "react";
import { Box } from "@mui/material";

import mail from "../Assets/mail.png"

const Contact = () => {
  return (

    <Box  className="contact-container">
      <div className="contact-box">
      <a id="contact" href="mailto:kingjackalopeman@aol.com" > 
        <div className="contact-box">
          <div className="contact-image-wrapper">
            <img className="contact-image" src={mail} alt="e-mail"/>
          </div>
          <div className="contact-text">
          CLICK HERE TO EMAIL ME
          </div>
        </div>
      </a>
      </div>
      <div className="contact-message-wrapper">
        <p className="contact-message">
          I will be donating 500,000 Pre-K through fourth-grade books in the coming year. 
          If your school or literacy organization would like to receive some donated books in English or Spanish, please email me. 
          Let me know how many books you need and how you will be using them. 
          Include as much contact information as possible, especially the best times to reach you.
          <br/><br/>
          Bruce "the Jackalope Man" Larkin
        </p>

      </div>


    </Box>
    
  );
};

export default Contact;
