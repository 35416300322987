// import contactme from "../Assets/contactme.png"
// import wilbookslogo from "../Assets/wilbookslogo.png";
// import colorincolorado from "../Assets/colorincolarado.png";
// import youtubelogo from "../Assets/youtubelogo.png";
// import dollypartonbanner from "../Assets/DPIL-Logo-195.png"
// import readingisfundamental from "../Assets/readingisfundemental.png"

export const FREESOURCES = [
  {
    id: 1,
    location: "California, San Francisco & Oakland",
    sources: [{
                url:  "https://www.eastbaychildrensbookproject.org/",
                text: "The East Bay Children's Book Project",
                logo: "eastbaychildrensbookproject.png"
    }],

  },
  {
    id: 2,
    location: "California, Southern California",
    sources: [{
                url:  "https://www.iecbp.org/",
                text: "Inland Empire Children's Book Project",
                logo: "iecbp.jpeg"
    }],

  },
  {
    id: 3,
    location: "Florida, Central Florida",
    sources: [{
                url:  "https://www.educationfoundation.com/",
                text: "The Hillsborough Education Foundation",
                logo: "hef-logo.svg"
              },
              {
                url:  "http://www.agiftforteaching.org/",
                text: "A Gift For Teaching",
                logo: "AGFT-25-Logo.RGB_-300x204.png"
              },
            ],

  },
  {
    id: 4,
    location: "Florida, Miami Area",
    sources: [{
                url:  "https://www.educationfund.org/",
                text: "The Education Fund",
                logo: "educationfund-color.jpg"
    }],

  },
  {
    id: 5,
    location: "Maine, Portland & Southern Maine",
    sources: [{
                url:  "https://www.ruths.org/",
                text: "Ruth's Reusable Resources",
                logo: "RRR-Logo-Web.webp"
              },
              {
                url:  "https://www.schoolhousesupplies.org/programs/the-free-store-for-teachers/",
                text: "The Free Store for Teachers",
                logo: "SHS_text_logo_green.png"
              },
            ],

  },
  {
    id: 6,
    location: "New York, Buffalo Area",
    sources: [{
                url:  "http://www.theteachersdesk.org/",
                text: "The Teacher's Desk",
                logo: "Teachers-Desk_logo-500-x-111.jpg"
    }],

  },
  {
    id: 7,
    location: "North Carolina, Durham",
    sources: [{
                url:  "http://www.crayons2calculators.org/",
                text: "Crayons 2 Calculators",
                logo: "C2C+logo-horizontal.jpg"
    }],

  },
  {
    id: 8,
    location: "Ohio, Cincinnati Area",
    sources: [{
                url:  "http://www.crayons2computers.org/",
                text: "Crayons To Computers",
                logo: "Crayons_Logo_RGB_Hor_R.jpg"
    }],

  },
  {
    id: 9,
    location: "Virginia, Suffolk Area",
    sources: [{
                url:  "https://www.suffolkeducationfoundation.org/teacher-store",
                text: "Teacher Supply Store",
                logo: "teacher_store_logo.webp"
    }],

  },
];