import { Box } from "@mui/material";
import React from "react";

import { FREESOURCES } from "../Data/freeSources";
import { FreeSource } from "./freeSource";




const FreeSources = () => {
  return (
    <Box className="block-container"
      // gridColumn="span 8"
      // gridRow="span 3"
       backgroundColor="#58D68D"
      // marginBottom="10px"
    >

      <Box className="header-container">
        <div className="header-container">

            <p className="header-element" >
            Sources For Free Books And School Supplies
            </p>

        </div>
      </Box>

      <div className="resources">
        {FREESOURCES.map((freeSource) => (
          <FreeSource data={freeSource} />
        ))}
      </div>

  

    </Box>

  
  );

};

export { FreeSources};
